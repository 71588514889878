import React, { useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { ChainId } from 'sdk/constants'
import { SUPPORTED_CHAIN_IDS_FOR_DEFI_FEATURES, CHAIN_IDS_WITHOUT_EARN } from 'components/Header/constants'
import { SwapIcon } from 'icons/SwapIcon'
import { ReactComponent as TokensIcon } from 'assets/svg/tokens-icon.svg'
import { FarmIcon } from 'icons/FarmIcon'
import { BridgeIcon } from 'icons/BridgeIcon'
import { FaucetIcon } from 'icons/Faucet'
import { useChainId } from 'hooks'
import {
  HeaderBox,
  HeaderFrame,
  HeaderLinks,
  StLink,
  StLinkIcon,
  ExtendedStyledMenuButton,
  DropdownWrapper,
} from './styles'
import { useTranslation } from 'react-i18next'
import { ConvertIcon } from 'icons/ConvertIcon'
// import { ALLOWED_CHAIND_IDS } from 'components/Header/constants'
import { TradeDropdown } from 'components/Header/components/TradeDropdown'
import { BuyDropdown } from 'components/Header/components/BuyDropdown'
import { EarnDropdown } from 'components/Header/components/EarnDropdown'
import { MoreIcon } from 'icons/MoreIcon'
import { MoreDropdown } from 'components/Header/components/MoreDropdown'
import { EcosystemDropdown } from 'components/Header/components/EcosystemDropdown'
import { CommunityMobileDropdown } from 'components/Header/components/CommunityMobileDropdown'
import { AnalyticsDropdown } from 'components/Header/components/AnalyticsDropdown'
import { EarnDropdownMobile } from 'components/Header/components/EarnDropdownMobile'

export const MobileNavigation: React.FC = () => {
  const { t } = useTranslation('navbar')
  const { pathname } = useLocation()

  const chainId = useChainId()
  const chainIdOnlysearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])

  const [isMoreMenuOpen, toggleMoreMenu] = useState(false)
  const [isLiquidityMenuOpen, toggleLiquidityMenu] = useState(false)
  const [isEarnMenuOpen, toggleEarnMenu] = useState(false)
  const [isLegacyMenuOpen, toggleLegacyMenu] = useState(false)
  const [isLendMenuOpen, toggleLendMenu] = useState(false)
  const [isStatsMenuOpen, toggleStatsMenu] = useState(false)
  const [isOtherMenuOpen, toggleOtherMenu] = useState(false)
  const [isTradeMenuOpen, toggleTradeMenu] = useState(false)
  const [isBuyMenuOpen, toggleBuyMenu] = useState(false)
  const [isEcosMenuOpen, toggleEcosMenu] = useState(false)
  const [isCommMenuOpen, toggleCommMenu] = useState(false)

  const menuRef = useRef<HTMLDivElement | null>(null)
  const otherRef = useRef<HTMLDivElement | null>(null)
  const liqRef = useRef<HTMLDivElement | null>(null)
  const earnRef = useRef<HTMLDivElement | null>(null)
  const legacyRef = useRef<HTMLDivElement | null>(null)
  const lendRef = useRef<HTMLDivElement | null>(null)
  const statsRef = useRef<HTMLDivElement | null>(null)
  const tradeRef = useRef<HTMLDivElement | null>(null)
  const buyRef = useRef<HTMLDivElement | null>(null)
  const ecosRef = useRef<HTMLDivElement | null>(null)
  const commRef = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(menuRef, isMoreMenuOpen ? () => toggleMoreMenu(!isMoreMenuOpen) : undefined)
  useOnClickOutside(liqRef, isLiquidityMenuOpen ? () => toggleLiquidityMenu(!isLiquidityMenuOpen) : undefined)
  useOnClickOutside(earnRef, isEarnMenuOpen ? () => toggleEarnMenu(!isEarnMenuOpen) : undefined)
  useOnClickOutside(legacyRef, isLegacyMenuOpen ? () => toggleLegacyMenu(!isLegacyMenuOpen) : undefined)
  useOnClickOutside(lendRef, isLendMenuOpen ? () => toggleLendMenu(!isLendMenuOpen) : undefined)
  useOnClickOutside(statsRef, isStatsMenuOpen ? () => toggleStatsMenu(!isStatsMenuOpen) : undefined)
  useOnClickOutside(otherRef, isOtherMenuOpen ? () => toggleOtherMenu(!isOtherMenuOpen) : undefined)
  useOnClickOutside(tradeRef, isTradeMenuOpen ? () => toggleTradeMenu(!isTradeMenuOpen) : undefined)
  useOnClickOutside(buyRef, isBuyMenuOpen ? () => toggleBuyMenu(!isBuyMenuOpen) : undefined)
  useOnClickOutside(ecosRef, isEcosMenuOpen ? () => toggleEcosMenu(!isEcosMenuOpen) : undefined)
  useOnClickOutside(commRef, isCommMenuOpen ? () => toggleCommMenu(!isCommMenuOpen) : undefined)

  const isSwapPages = pathname.startsWith('/swap') || pathname.startsWith('/token')
  const isBridgePage = pathname.startsWith('/bridge')
  const isMasonryPage = pathname.startsWith('/masonry')
  const isMortuaryPage = pathname.startsWith('/terrace')
  const isGeenhousePage = pathname.startsWith('/staking')
  const isPitPage = pathname.startsWith('/pit')
  const isLiquidityPage = pathname.startsWith('/add') || pathname.startsWith('/pool')
  const isGenesisPage = pathname.startsWith('/nursery')
  const isPortfolioPage = location.pathname.startsWith('/portfolio')
  const isBuyPage = location.pathname.startsWith('/buy-crypto')
  const isConvertPage = pathname.startsWith('/convert')
  const isEarnPages =
    pathname.startsWith('/garden') ||
    pathname.startsWith('/pool/add') ||
    pathname.startsWith('/staking') ||
    pathname.startsWith('/fountain') ||
    pathname.startsWith('/nursery') ||
    pathname.startsWith('/terrace')
  const isBuyPages = pathname.startsWith('/how-to-buy') || pathname.startsWith('/buy-crypto')
  const isFaucetPage = pathname.startsWith('/chain/testnet/faucet')

  const marketSearchParams = useMemo(
    () => new URLSearchParams({ chainId: String(chainId), swapType: 'Market' }),
    [chainId]
  )

  const chainsWithConvert = SUPPORTED_CHAIN_IDS_FOR_DEFI_FEATURES.includes(chainId)
  const chainsWithoutEarn = !CHAIN_IDS_WITHOUT_EARN.includes(chainId)

  const isBgActive =
    isTradeMenuOpen ||
    isBuyMenuOpen ||
    isEarnMenuOpen ||
    isMoreMenuOpen ||
    isLegacyMenuOpen ||
    isEcosMenuOpen ||
    isCommMenuOpen ||
    isStatsMenuOpen

  return (
    <HeaderBox $isActive={isBgActive}>
      <HeaderFrame>
        <HeaderLinks>
          {/* <DropdownWrapper $isActive={isTradeMenuOpen} ref={tradeRef} onClick={() => toggleTradeMenu(!isTradeMenuOpen)}>
            <ExtendedStyledMenuButton $isActive={isTradeMenuOpen || isSwapPages}>
              <StLinkIcon>
                <SwapIcon />
              </StLinkIcon>
              {t('swap')}
            </ExtendedStyledMenuButton>
            <TradeDropdown />
          </DropdownWrapper> */}
          {chainId === ChainId.LIF3CHAIN_TESTNET ? (
            <StLink $isActive={isSwapPages} to={{ pathname: '/swap', search: marketSearchParams.toString() }}>
              <StLinkIcon>
                <SwapIcon />
              </StLinkIcon>
              {t('swap')}
            </StLink>
          ) : (
            <StLink $isActive={isSwapPages} to={{ pathname: '/swap', search: chainIdOnlysearchParams.toString() }}>
              <StLinkIcon>
                <SwapIcon />
              </StLinkIcon>
              {t('swap')}
            </StLink>
          )}
          {chainId !== ChainId.LIF3CHAIN_TESTNET && (
            <DropdownWrapper $isActive={isBuyMenuOpen} ref={buyRef} onClick={() => toggleBuyMenu(!isBuyMenuOpen)}>
              <ExtendedStyledMenuButton $isActive={isBuyMenuOpen || isBuyPages}>
                <StLinkIcon>
                  <TokensIcon />
                </StLinkIcon>
                {t('buy')}
              </ExtendedStyledMenuButton>
              <BuyDropdown />
            </DropdownWrapper>
          )}
          <StLink $isActive={isBridgePage} to={{ pathname: '/bridge', search: chainIdOnlysearchParams.toString() }}>
            <StLinkIcon>
              <BridgeIcon />
            </StLinkIcon>
            {t('bridge')}
          </StLink>
          {chainsWithoutEarn && (
            <DropdownWrapper
              $isActive={isLegacyMenuOpen}
              ref={legacyRef}
              onClick={() => toggleLegacyMenu(!isLegacyMenuOpen)}
            >
              <ExtendedStyledMenuButton $isActive={isLegacyMenuOpen}>
                <StLinkIcon>
                  <FarmIcon />
                </StLinkIcon>
                {t('staking')}
              </ExtendedStyledMenuButton>
              <EarnDropdownMobile earnState={isEarnMenuOpen} toggleEarn={toggleEarnMenu} />
            </DropdownWrapper>
          )}
          {chainId == ChainId.LIF3CHAIN_TESTNET && (
            <StLink
              $isActive={isFaucetPage}
              to={{ pathname: '/chain/testnet/faucet', search: chainIdOnlysearchParams.toString() }}
            >
              <StLinkIcon>
                <FaucetIcon />
              </StLinkIcon>
              {t('faucet')}
            </StLink>
          )}
          {chainsWithConvert && (
            <StLink isActive={isConvertPage} to={{ pathname: '/convert', search: chainIdOnlysearchParams.toString() }}>
              <StLinkIcon>
                <ConvertIcon />
              </StLinkIcon>
              {t('convert')}
            </StLink>
          )}
          <DropdownWrapper $isActive={isMoreMenuOpen} ref={menuRef} onClick={() => toggleMoreMenu(!isMoreMenuOpen)}>
            <ExtendedStyledMenuButton $isActive={isMoreMenuOpen}>
              <StLinkIcon>
                <MoreIcon />
              </StLinkIcon>
              {t('more')}
            </ExtendedStyledMenuButton>
            <MoreDropdown
              ecosState={isEcosMenuOpen}
              toggleEcos={toggleEcosMenu}
              commState={isCommMenuOpen}
              toggleComm={toggleCommMenu}
              statsState={isStatsMenuOpen}
              toggleStats={toggleStatsMenu}
            />
          </DropdownWrapper>
          <DropdownWrapper
            isHidden={!isEcosMenuOpen}
            $isActive={isEcosMenuOpen}
            ref={ecosRef}
            onClick={() => toggleEcosMenu(!isEcosMenuOpen)}
          >
            <EcosystemDropdown />
          </DropdownWrapper>
          <DropdownWrapper
            isHidden={!isCommMenuOpen}
            $isActive={isCommMenuOpen}
            ref={commRef}
            onClick={() => toggleCommMenu(!isCommMenuOpen)}
          >
            <CommunityMobileDropdown />
          </DropdownWrapper>
          <DropdownWrapper
            isHidden={!isStatsMenuOpen}
            $isActive={isStatsMenuOpen}
            ref={statsRef}
            onClick={() => toggleStatsMenu(!isStatsMenuOpen)}
          >
            <AnalyticsDropdown />
          </DropdownWrapper>
          <DropdownWrapper
            isHidden={!isEarnMenuOpen}
            $isActive={isEarnMenuOpen}
            ref={earnRef}
            onClick={() => toggleEarnMenu(!isEarnMenuOpen)}
          >
            <EarnDropdown />
          </DropdownWrapper>
        </HeaderLinks>
      </HeaderFrame>
    </HeaderBox>
  )
}

import axios from 'axios'
import { Trade } from '@uniswap/router-sdk'
import { Percent, TradeType } from '@uniswap/sdk-core'
import { Currency, CurrencyAmount, Ether, Token } from 'sdk/v3'
import { CurrencyAmount as CurrencyAmountV2, TokenAmount } from 'sdk'

import { useWeb3React } from '@web3-react/core'
import PriceImpactWarning from './PriceImpactWarning'
import SwapDetailsDropdown from './SwapDetailsDropdown'
import { MouseoverTooltip } from 'components/Tooltip'
import usePrevious from 'hooks/usePrevious'
import { useSwapCallback } from 'hooks/v3/useSwapCallback'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { ArrowDown, Info } from 'react-feather'
import { Text } from 'rebass'
import styled, { useTheme } from 'styled-components/macro'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonPrimaryGradient, ButtonPrimaryWhite } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, SwapCallbackError, SwapWrapper } from './styleds'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field, replaceSwapState } from 'state/swap/v3/actions'
import { useDerivedSwapInfo, useSwapActionHandlers } from 'state/swap/v3/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/swap/v3/reducer'
import { LinkStyledButton } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from 'utils/v3/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from 'utils/v3/prices'
import Loader from 'components/Loader'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { ROUTER_ADDRESSES } from 'constants/v3/addresses'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { TradeState } from 'hooks/v3/types'
import { useChainId } from 'hooks'
import { CurrencyDirection } from 'enums/common'
import { NATIVE_CURRENCY } from 'sdk'
import { DEFAULT_DEADLINE_FROM_NOW, ZERO_ADDRESS } from 'constants/v3'
import { SwapCurrenciesIcon } from 'icons/SwapCurrenciesIcon'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { listedTokensList } from 'constants/aggregatorTokensList'
import { createNumberForPopUp, getPopupText } from 'shared/utils/getPopupText'
import { odosBaseUrl } from 'sdk/constants'
import { formatAmount } from 'utils/formatAmount'
import { useLPorCurrencyBalanceQuery } from 'state/wallet/hooks'
import { formatToCurrency } from 'utils/formatters'

export const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  position: relative;
  border-radius: 12px;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.border1}`};
`

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

export function SwapV3({ className }: { className?: string }) {
  const connectedChainId = useChainId()
  return (
    <Swap
      className={className}
      prefilledState={{
        [Field.INPUT]: { currencyId: NATIVE_CURRENCY[connectedChainId].symbol },
        [Field.OUTPUT]: {
          currencyId:
            listedTokensList[connectedChainId].find((item: Currency) => item.symbol === 'LIF3')?.address ?? '',
        },
      }}
    />
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  prefilledState = {},
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string
  prefilledState?: Partial<SwapState>
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}) {
  const { account } = useWeb3React()
  const connectedChainId = useChainId()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()

  const theme = useTheme()
  const addPopup = useAddPopup()
  // toggle wallet when disconnected
  const toggleWalletDrawer = useWalletModalToggle()

  // for expert mode
  // const [isExpertMode] = useExpertModeManager()
  const isExpertMode = false
  // swap state
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { typedValue, recipient, independentField } = state
  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)
  const [isPriceFetched, setIsPriceFetched] = useState<boolean>(false)
  const [prices, setPrices] = useState<any>({
    [Field.INPUT]: {
      converted: 0,
      usd: 0,
    },
    [Field.OUTPUT]: {
      converted: 0,
      usd: 0,
    },
  })
  const [formatedAmount, setFormatedAmount] = useState('')

  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(state)
  const inputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(currencies[CurrencyDirection.INPUT])
  const outputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(currencies[CurrencyDirection.OUTPUT])
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)

  const refetchTokenPrices = async () => {
    try {
      if (isPriceFetched) {
        return
      }
      const tokenPriceBaseUrl = `${odosBaseUrl}/pricing/token`
      const from = (
        currencies[Field.INPUT] === NATIVE_CURRENCY[connectedChainId]
          ? ZERO_ADDRESS
          : currencies[Field.INPUT]?.wrapped?.address
      )?.toLowerCase()

      const { data: inputPriceUSDResponse } = await axios.get(
        `${tokenPriceBaseUrl}/${Number(connectedChainId)}/${from}`
      )
      const { price: inputPriceUSD } = inputPriceUSDResponse

      const to = (
        currencies[Field.OUTPUT] === NATIVE_CURRENCY[connectedChainId]
          ? ZERO_ADDRESS
          : currencies[Field.OUTPUT]?.wrapped?.address
      )?.toLowerCase()

      const { data: outputPriceUSDResponse } = await axios.get(`${tokenPriceBaseUrl}/${Number(connectedChainId)}/${to}`)
      const { price: outputPriceUSD } = outputPriceUSDResponse

      setIsPriceFetched(true)
      const outputPrice = +outputPriceUSD <= 0 ? 0 : +inputPriceUSD / +outputPriceUSD
      const inputPrice = +inputPriceUSD <= 0 ? 0 : +outputPriceUSD / +inputPriceUSD
      setPrices({
        [Field.INPUT]: {
          converted: inputPrice,
          usd: +inputPriceUSD,
        },
        [Field.OUTPUT]: {
          converted: outputPrice,
          usd: +outputPriceUSD,
        },
      })
    } catch {
      setIsPriceFetched(false)
    }
  }

  useEffect(() => {
    refetchTokenPrices()
  }, [currencies])

  useEffect(() => {
    setIsPriceFetched(false)
    setFormatedAmount('')
  }, [connectedChainId])

  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueTradeInput = (+typedValue || 0) * Number(parsedAmounts[Field.INPUT])
  const fiatValueTradeOutput = (+formatedAmount || 0) * Number(parsedAmounts[Field.OUTPUT])

  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade ? undefined : computeFiatValuePriceImpact(fiatValueTradeInput, fiatValueTradeOutput),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const [inputValueToSwap, setInputValueToSwap] = useState<string>('')

  const handleTypeInput = (value: string) => {
    setInputValueToSwap(value)
    onUserInput(Field.INPUT, value)
    const outputValue = +value * prices[Field.OUTPUT].converted
    let formattedOutputValue = outputValue.toFixed(20)
    formattedOutputValue = formattedOutputValue.replace(/\.?0+$/, '')
    setFormatedAmount(!value ? '' : formattedOutputValue)
  }

  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const swapIsUnsupported = false

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: parsedAmounts[dependentField],
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue, inputValueToSwap]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && +formatedAmount > 0
  )

  const [allowanceState, approve] = useApproveCallback(
    currencies.INPUT?.isNative
      ? CurrencyAmountV2.ether(trade?.inputAmount.quotient.toString() ?? '0', connectedChainId)
      : currencies.INPUT
      ? new TokenAmount(currencies.INPUT, trade?.inputAmount.quotient.toString() ?? '0')
      : undefined,
    ROUTER_ADDRESSES[connectedChainId]
  )

  const isApprovalLoading = allowanceState === ApprovalState.PENDING

  const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const maxOutputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.OUTPUT])

  // the callback to execute the swap
  const swapCallback = useSwapCallback(
    trade,
    { slippageTolerance: allowedSlippage, deadline: DEFAULT_DEADLINE_FROM_NOW }

    // allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash: any) => {
        if (hash.error) {
          throw new Error(hash.error)
        }

        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: hash?.hash,
        })
        const inputAmount = formatToCurrency(createNumberForPopUp(Number(typedValue)))
        const outputAmount = formatToCurrency(
          createNumberForPopUp(Number((formattedAmounts[CurrencyDirection.OUTPUT] as any).toExact()))
        )
        const inputSymbol = currencies[CurrencyDirection.INPUT]?.symbol
        const outputSymbol = currencies[CurrencyDirection.OUTPUT]?.symbol

        if (hash && hash.hash && inputAmount && outputAmount && inputSymbol && outputSymbol) {
          addPopup(
            {
              txn: {
                hash: hash.hash,
                success: true,
                summary: getPopupText(
                  {
                    inputAmount,
                    outputAmount,
                    inputSymbol,
                    outputSymbol,
                  },
                  'V3'
                ),
              },
            },
            hash.hash
          )
          handleTypeInput('')
        }
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
        addPopup({
          notification: { success: false, text: error.message },
        })
      })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
  ])

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      const isNative = inputCurrency.symbol === NATIVE_CURRENCY[connectedChainId].symbol
      const currency =
        inputCurrency.symbol === NATIVE_CURRENCY[connectedChainId].symbol
          ? Ether.onChain(connectedChainId)
          : new Token(
              connectedChainId,
              inputCurrency.address,
              inputCurrency.decimals,
              inputCurrency.symbol,
              inputCurrency.name
            )

      onCurrencySelection(Field.INPUT, currency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: isNative ? currency.symbol : currency.address,
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
    },
    [onCurrencyChange, onCurrencySelection, state, connectedChainId]
  )

  const handleMaxInput = () => {
    const maxOutputAmount = Number(inputCurrencyBalanceQuery.data?.toExact())
    const value = maxOutputAmount * (prices[Field.OUTPUT].converted as any)
    onUserInput(Field.INPUT, formatAmount(maxOutputAmount))
    setFormatedAmount(String(value))
  }

  const handleMaxOutput = async () => {
    const maxOutputAmount = Number(outputCurrencyBalanceQuery?.data?.toExact())
    if (maxOutputAmount && currencies[Field.INPUT] && currencies[Field.OUTPUT]) {
      const value = maxOutputAmount * (prices[Field.INPUT].converted as any)
      setFormatedAmount(String(maxOutputAmount))
      onUserInput(Field.INPUT, formatAmount(value))
    }
  }

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      const currency =
        outputCurrency.symbol === NATIVE_CURRENCY[connectedChainId].symbol
          ? Ether.onChain(connectedChainId)
          : new Token(
              connectedChainId,
              outputCurrency.address,
              outputCurrency.decimals,
              outputCurrency.symbol,
              outputCurrency.name
            )

      onCurrencySelection(Field.OUTPUT, currency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: currency.isNative ? currency.symbol : currency.address,
        },
      })
    },
    [onCurrencyChange, onCurrencySelection, state, connectedChainId]
  )

  const handleSwithTokens = () => {
    onSwitchTokens()
    setPrices({
      [Field.INPUT]: {
        ...prices[Field.INPUT],
        converted: prices[Field.OUTPUT].converted,
      },
      [Field.OUTPUT]: {
        ...prices[Field.OUTPUT],
        converted: prices[Field.INPUT].converted,
      },
    })
    const outputValue = +typedValue * prices[Field.INPUT].converted
    setFormatedAmount(String(outputValue))
  }

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  useEffect(() => {
    setInputValueToSwap('')
  }, [connectedChainId])

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)

      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  return (
    <SwapWrapper chainId={connectedChainId} className={className} id="swap-page">
      <ConfirmSwapModal
        isOpen={showConfirm}
        trade={trade}
        originalTrade={tradeToConfirm}
        onAcceptChanges={handleAcceptChanges}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        recipient={recipient}
        allowedSlippage={allowedSlippage}
        onConfirm={handleSwap}
        swapErrorMessage={swapErrorMessage}
        onDismiss={handleConfirmDismiss}
        swapQuoteReceivedDate={swapQuoteReceivedDate}
        priceImpactSeverity={priceImpactSeverity}
      />

      <SwapSection>
        <CurrencyInputPanel
          label={independentField === Field.OUTPUT && !showWrap ? 'From (at most)' : 'From'}
          disabled={disableTokenInputs}
          value={typedValue}
          showMaxButton={true}
          currency={currencies[Field.INPUT] ?? null}
          onUserInput={handleTypeInput}
          onMax={handleMaxInput}
          onCurrencySelect={handleInputSelect}
          otherCurrency={currencies[Field.OUTPUT]}
          showCommonBases={true}
          loading={independentField === Field.OUTPUT && routeIsSyncing}
          listType={'V3'}
          currencyDirection={CurrencyDirection.INPUT}
          isMarket={true}
          inputDisabled={!currencies[Field.OUTPUT]}
          showRefresh={true}
        />
      </SwapSection>
      <ArrowWrapper clickable={true}>
        <ArrowContainer
          data-testid="swap-currency-button"
          onClick={() => {
            !disableTokenInputs && handleSwithTokens()
          }}
        >
          <SwapCurrenciesIcon />
        </ArrowContainer>
      </ArrowWrapper>
      <AutoColumn gap="16px">
        <OutputSwapSection>
          <CurrencyInputPanel
            value={formatedAmount}
            disabled={disableTokenInputs}
            onUserInput={handleTypeOutput}
            currencyDirection={CurrencyDirection.OUTPUT}
            label={independentField === Field.INPUT && !showWrap ? 'To (at least)' : 'To'}
            showMaxButton={true}
            onMax={handleMaxOutput}
            hideBalance={false}
            priceImpact={stablecoinPriceImpact}
            currency={currencies[Field.OUTPUT] ?? null}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[Field.INPUT]}
            listType={'V3'}
            showCommonBases={true}
            loading={independentField === Field.INPUT && routeIsSyncing}
            isMarket={true}
            inputDisabled={true}
          />
          {recipient !== null && !showWrap ? (
            <>
              <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable={false}>
                  <ArrowDown size="16" color={theme.text2} />
                </ArrowWrapper>
                <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                  - Remove recipient
                </LinkStyledButton>
              </AutoRow>
              <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
            </>
          ) : null}
        </OutputSwapSection>

        {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
        <div>
          {swapIsUnsupported ? (
            <ButtonPrimaryWhite disabled={true}>
              <Text>Unsupported Asset</Text>
            </ButtonPrimaryWhite>
          ) : !account ? (
            <ButtonPrimaryGradient onClick={toggleWalletDrawer}>
              <Text>Connect Wallet</Text>
            </ButtonPrimaryGradient>
          ) : connectedChainId && connectedChainId !== connectedChainId ? (
            <ButtonPrimaryWhite onClick={toggleWalletDrawer}>Connect to network</ButtonPrimaryWhite>
          ) : showWrap ? (
            <ButtonPrimaryWhite
              disabled={Boolean(wrapInputError)}
              onClick={onWrap}
              fontWeight={600}
              data-testid="wrap-button"
            >
              {wrapInputError
                ? wrapInputError
                : wrapType === WrapType.WRAP
                ? 'Wrap'
                : wrapType === WrapType.UNWRAP
                ? 'Unwrap'
                : null}
            </ButtonPrimaryWhite>
          ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
            <ButtonError disabled>
              <Text>Insufficient liquidity for this trade.</Text>
            </ButtonError>
          ) : isValid && (allowanceState === ApprovalState.NOT_APPROVED || allowanceState === ApprovalState.PENDING) ? (
            <ButtonPrimaryWhite
              onClick={approve}
              disabled={isApprovalLoading}
              style={{ gap: 14 }}
              data-testid="swap-approve-button"
            >
              {isApprovalLoading ? (
                <>
                  <Loader size="20px" />
                  Approval pending
                </>
              ) : (
                <>
                  <div style={{ height: 20 }}>
                    <MouseoverTooltip text={'Permission is required to swap each token'}>
                      <Info size={20} />
                    </MouseoverTooltip>
                  </div>
                  Approve use of {currencies[Field.INPUT]?.symbol}
                </>
              )}
            </ButtonPrimaryWhite>
          ) : (
            <ButtonError
              onClick={() => {
                handleSwap()
              }}
              id="swap-button"
              disabled={
                !isValid ||
                routeIsSyncing ||
                routeIsLoading ||
                priceImpactTooHigh ||
                allowanceState !== ApprovalState.APPROVED ||
                attemptingTxn
              }
              error={isValid && priceImpactSeverity > 2 && allowanceState === ApprovalState.APPROVED}
            >
              <Text>
                {swapInputError
                  ? swapInputError
                  : routeIsSyncing || routeIsLoading
                  ? 'Swap'
                  : priceImpactTooHigh
                  ? 'Price Impact Too High'
                  : priceImpactSeverity > 2
                  ? 'Swap Anyway'
                  : 'Swap'}
              </Text>
            </ButtonError>
          )}
          {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
        </div>

        {showDetailsDropdown && (
          <SwapDetailsDropdown
            trade={trade}
            syncing={routeIsSyncing}
            loading={routeIsLoading}
            allowedSlippage={allowedSlippage}
            alwaysOpen
            prices={prices}
          />
        )}
      </AutoColumn>
    </SwapWrapper>
  )
}

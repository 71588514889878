import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export const ankrBaseUrl = 'https://rpc.ankr.com'
export const ankrKey = '1033ee077473f248889041116c4df6c8d370a803b12080561a3f431cc6cec03e'

export const odosBaseUrl = 'https://api.odos.xyz'

export const polygonReferralCode = 3362457689

export enum ChainId {
  ETHEREUM = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  POLYGON = 137,
  AMOY_TESTNET = 80002,
  POLYGON_TESTNET = 80001,
  FANTOM = 250,
  FANTOM_TESTNET = 4002,
  XDAI = 100,
  BSC = 56,
  BSC_TESTNET = 97,
  ARBITRUM = 42161,
  MOONBASE = 1287,
  AVALANCHE = 43114,
  FUJI = 43113,
  HECO = 128,
  HECO_TESTNET = 256,
  METIS = 1088,
  CRONOS = 25,
  OPTIMISM = 10,
  BOBA = 288,
  TOMBCHAINTESTNET = 863,
  TOMB = 6969,
  BASE = 8453,
  LIF3CHAIN_TESTNET = 1811,
}

export enum Exchanges {
  ODOS = 'odos',
  ONEINCH = '1inch',
}

export const swapFee = 5

export const currentMainNetwork = ChainId.FANTOM

export const LSHARE_ADDRESS = {
  [ChainId.TOMB]: '0x4200000000000000000000000000000000000109',
  [ChainId.TOMBCHAINTESTNET]: '0x7c6b91D9Be155A6Db01f749217d76fF02A7227F2',
  [ChainId.FANTOM]: '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
}

export const TSHARE_ADDRESS = {
  [ChainId.FANTOM]: '0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
}

export const CHARTS_API_URL = {
  [ChainId.FANTOM]: 'https://charts.tomb.com/api',
  [ChainId.TOMB]: 'https://charts.lif3.com/api',
  [ChainId.TOMBCHAINTESTNET]: 'http://168.119.232.67:6777/api',
}

export const BRIDGE_ADDRESSES = {
  [ChainId.FANTOM]: '0x6753F6763D6Bacd951A1E80fF553ea39d53C7bd3',
  [ChainId.TOMBCHAINTESTNET]: '0xD092903876514939301bE30612bd351705bB8f54',
}

type NetworkConfig = {
  chainId: string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  rpcUrls: Array<string>
  blockExplorerUrls: Array<string>
}

export const SUPPORTED_NETWORKS: Record<number, NetworkConfig> = {
  [ChainId.ETHEREUM]: {
    chainId: `0x${ChainId.ETHEREUM.toString(16)}`,
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [`https://eth.merkle.io`],
    blockExplorerUrls: ['https://etherscan.com/'],
  },
  [ChainId.BSC]: {
    chainId: `0x${ChainId.BSC.toString(16)}`,
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org', 'https://bsc-dataseed1.defibit.io'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  [ChainId.FANTOM]: {
    chainId: `0x${ChainId.FANTOM.toString(16)}`,
    chainName: 'Fantom Opera',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: [`https://fantom.drpc.org`],
    blockExplorerUrls: ['https://ftmscan.com/'],
  },
  [ChainId.POLYGON]: {
    chainId: '0x89',
    chainName: 'Polygon Network',
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    nativeCurrency: {
      name: 'Matic Token',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  [ChainId.AMOY_TESTNET]: {
    chainId: '0x13882',
    chainName: 'Polygon Amoy Testnet',
    rpcUrls: [`https://rpc-amoy.polygon.technology/`],
    blockExplorerUrls: ['https://amoy.polygonscan.com/'],
    nativeCurrency: {
      name: 'Matic Token',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  [ChainId.ARBITRUM]: {
    chainId: `0x${ChainId.ARBITRUM.toString(16)}`,
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io/'],
  },
  [ChainId.OPTIMISM]: {
    chainId: `0x${ChainId.OPTIMISM.toString(16)}`,
    chainName: 'Optimism',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.optimism.io'],
    blockExplorerUrls: ['https://optimistic.etherscan.io/'],
  },
  [ChainId.BASE]: {
    chainId: `0x${ChainId.BASE.toString(16)}`,
    chainName: 'Base',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.base.org'],
    blockExplorerUrls: ['https://basescan.org/'],
  },
  [ChainId.LIF3CHAIN_TESTNET]: {
    chainId: `0x${ChainId.LIF3CHAIN_TESTNET.toString(16)}`,
    chainName: 'Lif3 Chain Testnet',
    nativeCurrency: {
      name: 'Lif3',
      symbol: 'LIF3',
      decimals: 18,
    },
    rpcUrls: ['https://testnet-evm.lif3.com'],
    blockExplorerUrls: ['https://testnet.lif3scout.com/'],
  },
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const INIT_CODE_HASH = {
  [ChainId.TOMBCHAINTESTNET]: '0x524726a6ad1f8d328cccfe4619a2590277fb5574076933d9a67af64083ddb461',
  [ChainId.FANTOM]: '0x2dfbcf1b907f911bc66d083d103a1d7de0b8b21a6cb2a66a78d1f1559018fba4',
  [ChainId.BSC]: '0x57cf36086932d62d26c562d18d87afe373f166c06011dc35be0783e29c6eb4eb',
  [ChainId.POLYGON]: '0x57cf36086932d62d26c562d18d87afe373f166c06011dc35be0783e29c6eb4eb',
  [ChainId.AMOY_TESTNET]: '0xde26c2a6658b6b7bf1691b5825395495a393c50acea2e3ef5d860ee432499942',
  [ChainId.ETHEREUM]: '0xf524e408e75d1e73784b81165db33b9ace66e7647166bf42f94d0cc179d4f9ac',
  [ChainId.OPTIMISM]: '0x57cf36086932d62d26c562d18d87afe373f166c06011dc35be0783e29c6eb4eb',
  [ChainId.ARBITRUM]: '0x57cf36086932d62d26c562d18d87afe373f166c06011dc35be0783e29c6eb4eb',
  [ChainId.BASE]: '0x57cf36086932d62d26c562d18d87afe373f166c06011dc35be0783e29c6eb4eb',
  [ChainId.LIF3CHAIN_TESTNET]: '0x55725de086eacf4ba48df5c39d7c4e736d9298de4193b49e45159f84522c89c6',
}

//only insert Multicall3 (3 is important) compatible contracts
export const MULTICALL_ADDRESSES = {
  [ChainId.ETHEREUM]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.AVALANCHE]: '',
  [ChainId.BSC]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.METIS]: '',
  [ChainId.FANTOM]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.POLYGON]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.AMOY_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.ARBITRUM]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.OPTIMISM]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.BOBA]: '',
  [ChainId.TOMBCHAINTESTNET]: '0xF87C36Fe0CacdD1334D542E00B4a5CDD19d21032',
  [ChainId.TOMB]: '0x9A79912305602863CD4C5facA24BA168f65f7020',
  [ChainId.BASE]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.LIF3CHAIN_TESTNET]: '0x6481515CFB93b48D1345De0c5B4F7552f4F14075',
}

export const FACTORY_ADDRESS: { [chainId: number]: string } = {
  [ChainId.ETHEREUM]: '0x28500192Ec2Bd53f20cc8056F44C72E3873d84A8',
  [ChainId.ROPSTEN]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.RINKEBY]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.GÖRLI]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.KOVAN]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.FANTOM]: '0xE236f6890F1824fa0a7ffc39b1597A5A6077Cfe9',
  [ChainId.FANTOM_TESTNET]: '',
  [ChainId.POLYGON]: '0x3FB1E7D5d9C974141A5B6E5fa4edab0a7Aa15C6A',
  [ChainId.POLYGON_TESTNET]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.AMOY_TESTNET]: '0xeD1E77786A491c3AC252E918E68E0b3B07721de8',
  [ChainId.XDAI]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.BSC]: '0x3FB1E7D5d9C974141A5B6E5fa4edab0a7Aa15C6A',
  [ChainId.BSC_TESTNET]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.ARBITRUM]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.OPTIMISM]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.MOONBASE]: '0x2Ce3F07dD4c62b56a502E223A7cBE38b1d77A1b5',
  [ChainId.AVALANCHE]: '',
  [ChainId.FUJI]: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
  [ChainId.HECO]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.HECO_TESTNET]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [ChainId.METIS]: '0x2CdFB20205701FF01689461610C9F321D1d00F80',
  [ChainId.TOMBCHAINTESTNET]: '0x82a401cBf933405d7E8B195da0A067512a0D5e10',
  [ChainId.TOMB]: '0x69Da8FFe6550A3D78dBff368194d490fB30703f9',
  [ChainId.BASE]: '0x69Da8FFe6550A3D78dBff368194d490fB30703f9',
  [ChainId.LIF3CHAIN_TESTNET]: '0xc31813C38b32C0f826c2dbF3360d5899740c9669',
}

export const ROUTER_ADDRESS: { [chainId: number]: string } = {
  [ChainId.ETHEREUM]: '0x9501165EF2962e5C0612D6C5A4b39d606b27E22f',
  [ChainId.RINKEBY]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.ROPSTEN]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.GÖRLI]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.KOVAN]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.FANTOM]: '0x6D0176C5ea1e44b08D3dd001b0784cE42F47a3A7',
  [ChainId.FANTOM_TESTNET]: '',
  [ChainId.POLYGON]: '0x67dC2703D306F72E94DbB0cE0aa0CB86149EAc70',
  [ChainId.POLYGON_TESTNET]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.XDAI]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.BSC]: '0x1956005bA9690B7A00b09c78769675C0cE1457AF',
  [ChainId.BSC_TESTNET]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.ARBITRUM]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.OPTIMISM]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.MOONBASE]: '0xeB5c2BB5E83B51d83F3534Ae21E84336B8B376ef',
  [ChainId.AVALANCHE]: '',
  [ChainId.FUJI]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.HECO]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.HECO_TESTNET]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  [ChainId.METIS]: '0x81b9FA50D5f5155Ee17817C21702C3AE4780AD09',
  [ChainId.TOMBCHAINTESTNET]: '0x0e7a2dBf4a59aadB6de1c2aEcC17F3A3a1a3F78b',
  [ChainId.TOMB]: '0xBA78Fb44c72c79C359625060E849FC73EA63183e',
  [ChainId.BASE]: '0xBA78Fb44c72c79C359625060E849FC73EA63183e',
  [ChainId.LIF3CHAIN_TESTNET]: '0x01C64888a06BDF1Fb5485F7200A445de390736Cf',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _995 = JSBI.BigInt(995)
export const _997 = JSBI.BigInt(997)
export const _998 = JSBI.BigInt(998)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}

export const defaultTokens = ['ETH', 'LIF3', 'USDT', 'DAI', 'USDC', 'LSHARE', 'L3USD']
